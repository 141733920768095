.musicPic {
  z-index: 100;
  cursor: pointer;
  position: fixed;
  width: 3em;
  height: 3em;
  bottom: 10%;
  animation: rotate linear 3s infinite;
  animation-play-state: paused;
  transition: all 0.3s;
}
.musicPic img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .musicPic {
    right: 10%;
    bottom: 20%;
  }
}
@media screen and (min-width: 1024px) {
  .musicPic {
    left: 10%;
  }
}
.rotate {
  animation-play-state: running;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
