.statusStyle {
  margin-bottom: 16px;
  color: #434e59;
  font-weight: 600;
  font-size: 30px;
}
.smallStyle {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
}
.rowStyle {
  align-items: center;
  display: flex;
  padding: 10%;
}
@media screen and (min-width: 1024px) {
  .statusStyle {
    font-size: 70px;
  }
  .smallStyle {
    font-size: 20px;
  }
}
