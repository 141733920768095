.download {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 20px;
  background: #ffffff;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  position: fixed;
  right: 10%;
  bottom: 20%;
  cursor: pointer;
}
.download:active {
  background: rgba(0, 0, 0, 0.8);
}
