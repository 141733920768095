.CommentList {
  padding: 0 1em;
  margin: 1em 0;
}
.CommentList .ant-comment-actions > li > span.del {
  color: #e38fa9;
}
.CommentList .ant-comment-content-author-name {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CommentList .content-box {
  overflow: hidden;
  text-align: justify;
}
.CommentList .content-box * {
  font-size: 14px!important;
}
.CommentList .content-box * img {
  max-width: 100px;
  border-radius: 5px;
}
