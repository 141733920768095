.List {
  margin: 4em 0;
}
.Artical-box {
  padding-left: 15%;
  margin-top: 3em;
}
.Artical-box .artical {
  width: 100%;
  position: relative;
  min-height: 0.3rem;
}
.Artical-box .dotts {
  left: 1.2%;
  width: 1.2em;
  height: 1.2em;
  border: 0.143em solid #e2e0dc;
  position: absolute;
  top: 0;
  line-height: 0;
  border-radius: 50%;
  background: #fff;
  margin-left: -0.6em;
  z-index: 10;
}
.Artical-box .date {
  display: block;
  position: absolute;
  top: -0.5em;
  margin-left: 3.5em;
  height: 2.14em;
  color: #948C76;
  z-index: 10;
  transition: 0.3s;
}
.Artical-box .date:hover {
  margin-left: 2.8em;
}
.Artical-box .date:hover .trig {
  border-right: 1.2em solid #e7d9ba;
}
.Artical-box .date:hover .dates {
  background: #e7d9ba;
}
.Artical-box .trig {
  position: absolute;
  left: -1.14em;
  top: 0;
  border-right: 16px solid #F7F3EE;
  border-top: 15px dashed transparent;
  border-bottom: 15px dashed transparent;
  width: 0;
  height: 0;
  line-height: 0;
}
.Artical-box .dates {
  display: inline-block;
  padding: 0 1em 0 1em;
  height: 30px;
  background: #F7F3EE;
  font-size: 0.8em;
  vertical-align: top;
  line-height: 2.5em;
}
.Artical-box .art-container {
  width: 100%;
  padding: 0 0 0 1%;
  position: relative;
}
.Artical-box .art-container .line {
  width: 0.143em;
  height: 100%;
  background: #F7F3EE;
  position: absolute;
  z-index: 0;
  transition: 0.5s;
}
.Artical-box .art-container .art-content {
  padding: 0 1em 1em 2em;
  overflow: hidden;
}
.Artical-box .art-container .art-title {
  margin: 3em 0 2em;
  font-size: 1em;
}
.Artical-box .art-container .art-title a {
  color: #444;
}
.Artical-box .art-container .art-img {
  width: 30%;
}
.Artical-box .art-container .art-img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.Artical-box .art-container .art-detail {
  width: 85%;
  line-height: 1.5em;
  margin-top: 2em;
  font-size: 0.8em;
}
.Artical-box .art-container .art-detail p {
  text-align: justify;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: 4.5em;
  overflow: hidden;
}
@media screen and (max-width: 450px) {
  .Artical-box {
    padding-left: 5%;
  }
  .Artical-box .art-container .art-title {
    font-size: 1em;
  }
  .Artical-box .art-container .art-content {
    padding-left: 1.5em;
  }
  .Artical-box .art-container .art-content .art-detail {
    font-size: 0.8em;
  }
  .Artical-box .art-container .art-img {
    width: 60%;
  }
  .Artical-box .art-container .art-img img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
